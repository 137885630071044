// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/dataschema/biovu.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra.dataschema";

export interface BioVU {
  sampleFilter: BioVU_SampleFilter;
  excludeCompromised: boolean;
  excludeInternal: boolean;
  plasma: boolean;
}

export enum BioVU_SampleFilter {
  SAMPLE_FILTER_UNKNOWN = 0,
  SAMPLE_FILTER_ANY = 1,
  SAMPLE_FILTER_ONE_HUNDRED = 2,
  SAMPLE_FILTER_FIVE_HUNDRED = 3,
  UNRECOGNIZED = -1,
}

export function bioVU_SampleFilterFromJSON(object: any): BioVU_SampleFilter {
  switch (object) {
    case 0:
    case "SAMPLE_FILTER_UNKNOWN":
      return BioVU_SampleFilter.SAMPLE_FILTER_UNKNOWN;
    case 1:
    case "SAMPLE_FILTER_ANY":
      return BioVU_SampleFilter.SAMPLE_FILTER_ANY;
    case 2:
    case "SAMPLE_FILTER_ONE_HUNDRED":
      return BioVU_SampleFilter.SAMPLE_FILTER_ONE_HUNDRED;
    case 3:
    case "SAMPLE_FILTER_FIVE_HUNDRED":
      return BioVU_SampleFilter.SAMPLE_FILTER_FIVE_HUNDRED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BioVU_SampleFilter.UNRECOGNIZED;
  }
}

export function bioVU_SampleFilterToJSON(object: BioVU_SampleFilter): string {
  switch (object) {
    case BioVU_SampleFilter.SAMPLE_FILTER_UNKNOWN:
      return "SAMPLE_FILTER_UNKNOWN";
    case BioVU_SampleFilter.SAMPLE_FILTER_ANY:
      return "SAMPLE_FILTER_ANY";
    case BioVU_SampleFilter.SAMPLE_FILTER_ONE_HUNDRED:
      return "SAMPLE_FILTER_ONE_HUNDRED";
    case BioVU_SampleFilter.SAMPLE_FILTER_FIVE_HUNDRED:
      return "SAMPLE_FILTER_FIVE_HUNDRED";
    case BioVU_SampleFilter.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseBioVU(): BioVU {
  return { sampleFilter: 0, excludeCompromised: false, excludeInternal: false, plasma: false };
}

export const BioVU = {
  encode(message: BioVU, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sampleFilter !== 0) {
      writer.uint32(8).int32(message.sampleFilter);
    }
    if (message.excludeCompromised !== false) {
      writer.uint32(16).bool(message.excludeCompromised);
    }
    if (message.excludeInternal !== false) {
      writer.uint32(24).bool(message.excludeInternal);
    }
    if (message.plasma !== false) {
      writer.uint32(32).bool(message.plasma);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BioVU {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBioVU();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sampleFilter = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.excludeCompromised = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.excludeInternal = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.plasma = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BioVU {
    return {
      sampleFilter: isSet(object.sampleFilter) ? bioVU_SampleFilterFromJSON(object.sampleFilter) : 0,
      excludeCompromised: isSet(object.excludeCompromised) ? globalThis.Boolean(object.excludeCompromised) : false,
      excludeInternal: isSet(object.excludeInternal) ? globalThis.Boolean(object.excludeInternal) : false,
      plasma: isSet(object.plasma) ? globalThis.Boolean(object.plasma) : false,
    };
  },

  toJSON(message: BioVU): unknown {
    const obj: any = {};
    if (message.sampleFilter !== 0) {
      obj.sampleFilter = bioVU_SampleFilterToJSON(message.sampleFilter);
    }
    if (message.excludeCompromised !== false) {
      obj.excludeCompromised = message.excludeCompromised;
    }
    if (message.excludeInternal !== false) {
      obj.excludeInternal = message.excludeInternal;
    }
    if (message.plasma !== false) {
      obj.plasma = message.plasma;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BioVU>, I>>(base?: I): BioVU {
    return BioVU.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BioVU>, I>>(object: I): BioVU {
    const message = createBaseBioVU();
    message.sampleFilter = object.sampleFilter ?? 0;
    message.excludeCompromised = object.excludeCompromised ?? false;
    message.excludeInternal = object.excludeInternal ?? false;
    message.plasma = object.plasma ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

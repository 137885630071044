// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/configschema/unhinted_value.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra.configschema";

export interface UnhintedValue {
  groupByCount: boolean;
  attributes: { [key: string]: UnhintedValue_AttributeList };
}

export interface UnhintedValue_AttributeList {
  values: string[];
}

export interface UnhintedValue_AttributesEntry {
  key: string;
  value: UnhintedValue_AttributeList | undefined;
}

function createBaseUnhintedValue(): UnhintedValue {
  return { groupByCount: false, attributes: {} };
}

export const UnhintedValue = {
  encode(message: UnhintedValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.groupByCount !== false) {
      writer.uint32(8).bool(message.groupByCount);
    }
    Object.entries(message.attributes).forEach(([key, value]) => {
      UnhintedValue_AttributesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnhintedValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnhintedValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.groupByCount = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = UnhintedValue_AttributesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.attributes[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnhintedValue {
    return {
      groupByCount: isSet(object.groupByCount) ? globalThis.Boolean(object.groupByCount) : false,
      attributes: isObject(object.attributes)
        ? Object.entries(object.attributes).reduce<{ [key: string]: UnhintedValue_AttributeList }>(
          (acc, [key, value]) => {
            acc[key] = UnhintedValue_AttributeList.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: UnhintedValue): unknown {
    const obj: any = {};
    if (message.groupByCount !== false) {
      obj.groupByCount = message.groupByCount;
    }
    if (message.attributes) {
      const entries = Object.entries(message.attributes);
      if (entries.length > 0) {
        obj.attributes = {};
        entries.forEach(([k, v]) => {
          obj.attributes[k] = UnhintedValue_AttributeList.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnhintedValue>, I>>(base?: I): UnhintedValue {
    return UnhintedValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnhintedValue>, I>>(object: I): UnhintedValue {
    const message = createBaseUnhintedValue();
    message.groupByCount = object.groupByCount ?? false;
    message.attributes = Object.entries(object.attributes ?? {}).reduce<{ [key: string]: UnhintedValue_AttributeList }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = UnhintedValue_AttributeList.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseUnhintedValue_AttributeList(): UnhintedValue_AttributeList {
  return { values: [] };
}

export const UnhintedValue_AttributeList = {
  encode(message: UnhintedValue_AttributeList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnhintedValue_AttributeList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnhintedValue_AttributeList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnhintedValue_AttributeList {
    return {
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: UnhintedValue_AttributeList): unknown {
    const obj: any = {};
    if (message.values?.length) {
      obj.values = message.values;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnhintedValue_AttributeList>, I>>(base?: I): UnhintedValue_AttributeList {
    return UnhintedValue_AttributeList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnhintedValue_AttributeList>, I>>(object: I): UnhintedValue_AttributeList {
    const message = createBaseUnhintedValue_AttributeList();
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseUnhintedValue_AttributesEntry(): UnhintedValue_AttributesEntry {
  return { key: "", value: undefined };
}

export const UnhintedValue_AttributesEntry = {
  encode(message: UnhintedValue_AttributesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      UnhintedValue_AttributeList.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnhintedValue_AttributesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnhintedValue_AttributesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = UnhintedValue_AttributeList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnhintedValue_AttributesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? UnhintedValue_AttributeList.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: UnhintedValue_AttributesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = UnhintedValue_AttributeList.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnhintedValue_AttributesEntry>, I>>(base?: I): UnhintedValue_AttributesEntry {
    return UnhintedValue_AttributesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnhintedValue_AttributesEntry>, I>>(
    object: I,
  ): UnhintedValue_AttributesEntry {
    const message = createBaseUnhintedValue_AttributesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? UnhintedValue_AttributeList.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/configschema/multi_attribute.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ValueConfig } from "../value_config";

export const protobufPackage = "tanagra.configschema";

/**
 * A criteria based on one or more categorical (i.e. an enum) or numeric
 * attribute of an entity. Can be configured to show all attributes or switch
 * between them.
 */
export interface MultiAttribute {
  /** The entity to filter on. */
  entity: string;
  /**
   * Whether the user selects a single attribute to filter on or filters on all
   * of them simultaneously.
   */
  singleValue: boolean;
  /** Configuration for each filterable attribute. */
  valueConfigs: ValueConfig[];
}

function createBaseMultiAttribute(): MultiAttribute {
  return { entity: "", singleValue: false, valueConfigs: [] };
}

export const MultiAttribute = {
  encode(message: MultiAttribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity !== "") {
      writer.uint32(10).string(message.entity);
    }
    if (message.singleValue !== false) {
      writer.uint32(16).bool(message.singleValue);
    }
    for (const v of message.valueConfigs) {
      ValueConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MultiAttribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMultiAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entity = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.singleValue = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.valueConfigs.push(ValueConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MultiAttribute {
    return {
      entity: isSet(object.entity) ? globalThis.String(object.entity) : "",
      singleValue: isSet(object.singleValue) ? globalThis.Boolean(object.singleValue) : false,
      valueConfigs: globalThis.Array.isArray(object?.valueConfigs)
        ? object.valueConfigs.map((e: any) => ValueConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MultiAttribute): unknown {
    const obj: any = {};
    if (message.entity !== "") {
      obj.entity = message.entity;
    }
    if (message.singleValue !== false) {
      obj.singleValue = message.singleValue;
    }
    if (message.valueConfigs?.length) {
      obj.valueConfigs = message.valueConfigs.map((e) => ValueConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultiAttribute>, I>>(base?: I): MultiAttribute {
    return MultiAttribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MultiAttribute>, I>>(object: I): MultiAttribute {
    const message = createBaseMultiAttribute();
    message.entity = object.entity ?? "";
    message.singleValue = object.singleValue ?? false;
    message.valueConfigs = object.valueConfigs?.map((e) => ValueConfig.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: viz/viz_config.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra.viz";

/** The configuration of a underlay or cohort level visualization. */
export interface VizConfig {
  /** The visualization must have one or two sources of data to display. */
  sources: VizConfig_Source[];
}

export interface VizConfig_Source {
  /**
   * The criteria selector (e.g. condition) determines which entities the data
   * is being pulled from.
   */
  criteriaSelector: string;
  /**
   * Configuration of the specified criteria selection (e.g. to select
   * conditions under diabetes).
   */
  selectionData?:
    | string
    | undefined;
  /** For criteria selectors that return more than one entity. */
  entity?:
    | string
    | undefined;
  /**
   * To visualize data from different entities, the data must be joined to a
   * common entity. Each source must specify a series of joins that ends up at
   * the same entity if it does not already come from that entity.
   */
  joins: VizConfig_Source_Join[];
  /**
   * Which attributes should be returned from the selected data source (e.g.
   * condition_name from condition_occurrence or age from person).
   */
  attributes: VizConfig_Source_Attribute[];
}

export interface VizConfig_Source_Join {
  /**
   * The next entity to join to in order to eventually get to the entity the
   * visualization is displaying (e.g. person when joining
   * condition_occurences to age).
   */
  entity: string;
  /**
   * When joining an entity with an N:1 relationship (e.g. multiple weight
   * values to a person), an aggregation is often required to make the data
   * visualizable. For example, to visualize weight vs. race, each person
   * needs to have a single weight value associated with them, such as the
   * average or most recent. For simple cases, simply counting unique
   * instances of a related entity may be sufficient (e.g. to count people
   * with related condition occurrences).
   */
  aggregation?: VizConfig_Source_Join_Aggregation | undefined;
}

export interface VizConfig_Source_Join_Aggregation {
  /** The type of aggregation being performed. */
  type?:
    | VizConfig_Source_Join_Aggregation_AggregationType
    | undefined;
  /**
   * The output is always ids and values but aggregation may occur over
   * another field (e.g. date to find the most recent value).
   */
  attribute?: string | undefined;
}

export enum VizConfig_Source_Join_Aggregation_AggregationType {
  UNIQUE = 0,
  MIN = 1,
  MAX = 2,
  AVERAGE = 3,
  UNRECOGNIZED = -1,
}

export function vizConfig_Source_Join_Aggregation_AggregationTypeFromJSON(
  object: any,
): VizConfig_Source_Join_Aggregation_AggregationType {
  switch (object) {
    case 0:
    case "UNIQUE":
      return VizConfig_Source_Join_Aggregation_AggregationType.UNIQUE;
    case 1:
    case "MIN":
      return VizConfig_Source_Join_Aggregation_AggregationType.MIN;
    case 2:
    case "MAX":
      return VizConfig_Source_Join_Aggregation_AggregationType.MAX;
    case 3:
    case "AVERAGE":
      return VizConfig_Source_Join_Aggregation_AggregationType.AVERAGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VizConfig_Source_Join_Aggregation_AggregationType.UNRECOGNIZED;
  }
}

export function vizConfig_Source_Join_Aggregation_AggregationTypeToJSON(
  object: VizConfig_Source_Join_Aggregation_AggregationType,
): string {
  switch (object) {
    case VizConfig_Source_Join_Aggregation_AggregationType.UNIQUE:
      return "UNIQUE";
    case VizConfig_Source_Join_Aggregation_AggregationType.MIN:
      return "MIN";
    case VizConfig_Source_Join_Aggregation_AggregationType.MAX:
      return "MAX";
    case VizConfig_Source_Join_Aggregation_AggregationType.AVERAGE:
      return "AVERAGE";
    case VizConfig_Source_Join_Aggregation_AggregationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface VizConfig_Source_Attribute {
  /** The attribute to read. */
  attribute: string;
  numericBucketing?:
    | VizConfig_Source_Attribute_NumericBucketing
    | undefined;
  /** How to sort this attribute for display. Defaults to NAME. */
  sortType?:
    | VizConfig_Source_Attribute_SortType
    | undefined;
  /** Whether to sort in descending order. */
  sortDescending?:
    | boolean
    | undefined;
  /**
   * Whether a limited amount of data should be returned (e.g. 10 most
   * common conditions).
   */
  limit?: number | undefined;
}

export enum VizConfig_Source_Attribute_SortType {
  UNKNOWN = 0,
  NAME = 1,
  VALUE = 2,
  UNRECOGNIZED = -1,
}

export function vizConfig_Source_Attribute_SortTypeFromJSON(object: any): VizConfig_Source_Attribute_SortType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return VizConfig_Source_Attribute_SortType.UNKNOWN;
    case 1:
    case "NAME":
      return VizConfig_Source_Attribute_SortType.NAME;
    case 2:
    case "VALUE":
      return VizConfig_Source_Attribute_SortType.VALUE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VizConfig_Source_Attribute_SortType.UNRECOGNIZED;
  }
}

export function vizConfig_Source_Attribute_SortTypeToJSON(object: VizConfig_Source_Attribute_SortType): string {
  switch (object) {
    case VizConfig_Source_Attribute_SortType.UNKNOWN:
      return "UNKNOWN";
    case VizConfig_Source_Attribute_SortType.NAME:
      return "NAME";
    case VizConfig_Source_Attribute_SortType.VALUE:
      return "VALUE";
    case VizConfig_Source_Attribute_SortType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Converts a continuous numeric range into ids with count as the value. */
export interface VizConfig_Source_Attribute_NumericBucketing {
  /**
   * Buckets can be specified as either a list of thresholds or a range
   * and number of buckets. For thresholds [18, 45, 65], results in two
   * buckets [18, 45), and [45, 65). Lesser and greater buckets can be
   * added if desired.
   */
  thresholds: number[];
  intervals?:
    | VizConfig_Source_Attribute_NumericBucketing_Intervals
    | undefined;
  /**
   * Whether to create buckets for values outside of the explicitly
   * specified ones or ignore them.
   */
  includeLesser?: boolean | undefined;
  includeGreater?: boolean | undefined;
}

/**
 * For intervals {min:1, max:5, count: 2}, creates two buckets [1, 3)
 * and [3, 5).
 */
export interface VizConfig_Source_Attribute_NumericBucketing_Intervals {
  min: number;
  max: number;
  count: number;
}

function createBaseVizConfig(): VizConfig {
  return { sources: [] };
}

export const VizConfig = {
  encode(message: VizConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sources) {
      VizConfig_Source.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sources.push(VizConfig_Source.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig {
    return {
      sources: globalThis.Array.isArray(object?.sources)
        ? object.sources.map((e: any) => VizConfig_Source.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VizConfig): unknown {
    const obj: any = {};
    if (message.sources?.length) {
      obj.sources = message.sources.map((e) => VizConfig_Source.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig>, I>>(base?: I): VizConfig {
    return VizConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig>, I>>(object: I): VizConfig {
    const message = createBaseVizConfig();
    message.sources = object.sources?.map((e) => VizConfig_Source.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVizConfig_Source(): VizConfig_Source {
  return { criteriaSelector: "", selectionData: undefined, entity: undefined, joins: [], attributes: [] };
}

export const VizConfig_Source = {
  encode(message: VizConfig_Source, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.criteriaSelector !== "") {
      writer.uint32(10).string(message.criteriaSelector);
    }
    if (message.selectionData !== undefined) {
      writer.uint32(18).string(message.selectionData);
    }
    if (message.entity !== undefined) {
      writer.uint32(26).string(message.entity);
    }
    for (const v of message.joins) {
      VizConfig_Source_Join.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.attributes) {
      VizConfig_Source_Attribute.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.criteriaSelector = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selectionData = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entity = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.joins.push(VizConfig_Source_Join.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.attributes.push(VizConfig_Source_Attribute.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source {
    return {
      criteriaSelector: isSet(object.criteriaSelector) ? globalThis.String(object.criteriaSelector) : "",
      selectionData: isSet(object.selectionData) ? globalThis.String(object.selectionData) : undefined,
      entity: isSet(object.entity) ? globalThis.String(object.entity) : undefined,
      joins: globalThis.Array.isArray(object?.joins)
        ? object.joins.map((e: any) => VizConfig_Source_Join.fromJSON(e))
        : [],
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => VizConfig_Source_Attribute.fromJSON(e))
        : [],
    };
  },

  toJSON(message: VizConfig_Source): unknown {
    const obj: any = {};
    if (message.criteriaSelector !== "") {
      obj.criteriaSelector = message.criteriaSelector;
    }
    if (message.selectionData !== undefined) {
      obj.selectionData = message.selectionData;
    }
    if (message.entity !== undefined) {
      obj.entity = message.entity;
    }
    if (message.joins?.length) {
      obj.joins = message.joins.map((e) => VizConfig_Source_Join.toJSON(e));
    }
    if (message.attributes?.length) {
      obj.attributes = message.attributes.map((e) => VizConfig_Source_Attribute.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source>, I>>(base?: I): VizConfig_Source {
    return VizConfig_Source.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source>, I>>(object: I): VizConfig_Source {
    const message = createBaseVizConfig_Source();
    message.criteriaSelector = object.criteriaSelector ?? "";
    message.selectionData = object.selectionData ?? undefined;
    message.entity = object.entity ?? undefined;
    message.joins = object.joins?.map((e) => VizConfig_Source_Join.fromPartial(e)) || [];
    message.attributes = object.attributes?.map((e) => VizConfig_Source_Attribute.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVizConfig_Source_Join(): VizConfig_Source_Join {
  return { entity: "", aggregation: undefined };
}

export const VizConfig_Source_Join = {
  encode(message: VizConfig_Source_Join, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.entity !== "") {
      writer.uint32(10).string(message.entity);
    }
    if (message.aggregation !== undefined) {
      VizConfig_Source_Join_Aggregation.encode(message.aggregation, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source_Join {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source_Join();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.entity = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.aggregation = VizConfig_Source_Join_Aggregation.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source_Join {
    return {
      entity: isSet(object.entity) ? globalThis.String(object.entity) : "",
      aggregation: isSet(object.aggregation)
        ? VizConfig_Source_Join_Aggregation.fromJSON(object.aggregation)
        : undefined,
    };
  },

  toJSON(message: VizConfig_Source_Join): unknown {
    const obj: any = {};
    if (message.entity !== "") {
      obj.entity = message.entity;
    }
    if (message.aggregation !== undefined) {
      obj.aggregation = VizConfig_Source_Join_Aggregation.toJSON(message.aggregation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source_Join>, I>>(base?: I): VizConfig_Source_Join {
    return VizConfig_Source_Join.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source_Join>, I>>(object: I): VizConfig_Source_Join {
    const message = createBaseVizConfig_Source_Join();
    message.entity = object.entity ?? "";
    message.aggregation = (object.aggregation !== undefined && object.aggregation !== null)
      ? VizConfig_Source_Join_Aggregation.fromPartial(object.aggregation)
      : undefined;
    return message;
  },
};

function createBaseVizConfig_Source_Join_Aggregation(): VizConfig_Source_Join_Aggregation {
  return { type: undefined, attribute: undefined };
}

export const VizConfig_Source_Join_Aggregation = {
  encode(message: VizConfig_Source_Join_Aggregation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== undefined) {
      writer.uint32(8).int32(message.type);
    }
    if (message.attribute !== undefined) {
      writer.uint32(18).string(message.attribute);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source_Join_Aggregation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source_Join_Aggregation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.attribute = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source_Join_Aggregation {
    return {
      type: isSet(object.type) ? vizConfig_Source_Join_Aggregation_AggregationTypeFromJSON(object.type) : undefined,
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : undefined,
    };
  },

  toJSON(message: VizConfig_Source_Join_Aggregation): unknown {
    const obj: any = {};
    if (message.type !== undefined) {
      obj.type = vizConfig_Source_Join_Aggregation_AggregationTypeToJSON(message.type);
    }
    if (message.attribute !== undefined) {
      obj.attribute = message.attribute;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source_Join_Aggregation>, I>>(
    base?: I,
  ): VizConfig_Source_Join_Aggregation {
    return VizConfig_Source_Join_Aggregation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source_Join_Aggregation>, I>>(
    object: I,
  ): VizConfig_Source_Join_Aggregation {
    const message = createBaseVizConfig_Source_Join_Aggregation();
    message.type = object.type ?? undefined;
    message.attribute = object.attribute ?? undefined;
    return message;
  },
};

function createBaseVizConfig_Source_Attribute(): VizConfig_Source_Attribute {
  return {
    attribute: "",
    numericBucketing: undefined,
    sortType: undefined,
    sortDescending: undefined,
    limit: undefined,
  };
}

export const VizConfig_Source_Attribute = {
  encode(message: VizConfig_Source_Attribute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attribute !== "") {
      writer.uint32(10).string(message.attribute);
    }
    if (message.numericBucketing !== undefined) {
      VizConfig_Source_Attribute_NumericBucketing.encode(message.numericBucketing, writer.uint32(18).fork()).ldelim();
    }
    if (message.sortType !== undefined) {
      writer.uint32(24).int32(message.sortType);
    }
    if (message.sortDescending !== undefined) {
      writer.uint32(32).bool(message.sortDescending);
    }
    if (message.limit !== undefined) {
      writer.uint32(40).int64(message.limit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source_Attribute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source_Attribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attribute = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.numericBucketing = VizConfig_Source_Attribute_NumericBucketing.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sortType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sortDescending = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.limit = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source_Attribute {
    return {
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : "",
      numericBucketing: isSet(object.numericBucketing)
        ? VizConfig_Source_Attribute_NumericBucketing.fromJSON(object.numericBucketing)
        : undefined,
      sortType: isSet(object.sortType) ? vizConfig_Source_Attribute_SortTypeFromJSON(object.sortType) : undefined,
      sortDescending: isSet(object.sortDescending) ? globalThis.Boolean(object.sortDescending) : undefined,
      limit: isSet(object.limit) ? globalThis.Number(object.limit) : undefined,
    };
  },

  toJSON(message: VizConfig_Source_Attribute): unknown {
    const obj: any = {};
    if (message.attribute !== "") {
      obj.attribute = message.attribute;
    }
    if (message.numericBucketing !== undefined) {
      obj.numericBucketing = VizConfig_Source_Attribute_NumericBucketing.toJSON(message.numericBucketing);
    }
    if (message.sortType !== undefined) {
      obj.sortType = vizConfig_Source_Attribute_SortTypeToJSON(message.sortType);
    }
    if (message.sortDescending !== undefined) {
      obj.sortDescending = message.sortDescending;
    }
    if (message.limit !== undefined) {
      obj.limit = Math.round(message.limit);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source_Attribute>, I>>(base?: I): VizConfig_Source_Attribute {
    return VizConfig_Source_Attribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source_Attribute>, I>>(object: I): VizConfig_Source_Attribute {
    const message = createBaseVizConfig_Source_Attribute();
    message.attribute = object.attribute ?? "";
    message.numericBucketing = (object.numericBucketing !== undefined && object.numericBucketing !== null)
      ? VizConfig_Source_Attribute_NumericBucketing.fromPartial(object.numericBucketing)
      : undefined;
    message.sortType = object.sortType ?? undefined;
    message.sortDescending = object.sortDescending ?? undefined;
    message.limit = object.limit ?? undefined;
    return message;
  },
};

function createBaseVizConfig_Source_Attribute_NumericBucketing(): VizConfig_Source_Attribute_NumericBucketing {
  return { thresholds: [], intervals: undefined, includeLesser: undefined, includeGreater: undefined };
}

export const VizConfig_Source_Attribute_NumericBucketing = {
  encode(message: VizConfig_Source_Attribute_NumericBucketing, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.thresholds) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.intervals !== undefined) {
      VizConfig_Source_Attribute_NumericBucketing_Intervals.encode(message.intervals, writer.uint32(18).fork())
        .ldelim();
    }
    if (message.includeLesser !== undefined) {
      writer.uint32(24).bool(message.includeLesser);
    }
    if (message.includeGreater !== undefined) {
      writer.uint32(32).bool(message.includeGreater);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source_Attribute_NumericBucketing {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source_Attribute_NumericBucketing();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 9) {
            message.thresholds.push(reader.double());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.thresholds.push(reader.double());
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.intervals = VizConfig_Source_Attribute_NumericBucketing_Intervals.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeLesser = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeGreater = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source_Attribute_NumericBucketing {
    return {
      thresholds: globalThis.Array.isArray(object?.thresholds)
        ? object.thresholds.map((e: any) => globalThis.Number(e))
        : [],
      intervals: isSet(object.intervals)
        ? VizConfig_Source_Attribute_NumericBucketing_Intervals.fromJSON(object.intervals)
        : undefined,
      includeLesser: isSet(object.includeLesser) ? globalThis.Boolean(object.includeLesser) : undefined,
      includeGreater: isSet(object.includeGreater) ? globalThis.Boolean(object.includeGreater) : undefined,
    };
  },

  toJSON(message: VizConfig_Source_Attribute_NumericBucketing): unknown {
    const obj: any = {};
    if (message.thresholds?.length) {
      obj.thresholds = message.thresholds;
    }
    if (message.intervals !== undefined) {
      obj.intervals = VizConfig_Source_Attribute_NumericBucketing_Intervals.toJSON(message.intervals);
    }
    if (message.includeLesser !== undefined) {
      obj.includeLesser = message.includeLesser;
    }
    if (message.includeGreater !== undefined) {
      obj.includeGreater = message.includeGreater;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source_Attribute_NumericBucketing>, I>>(
    base?: I,
  ): VizConfig_Source_Attribute_NumericBucketing {
    return VizConfig_Source_Attribute_NumericBucketing.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source_Attribute_NumericBucketing>, I>>(
    object: I,
  ): VizConfig_Source_Attribute_NumericBucketing {
    const message = createBaseVizConfig_Source_Attribute_NumericBucketing();
    message.thresholds = object.thresholds?.map((e) => e) || [];
    message.intervals = (object.intervals !== undefined && object.intervals !== null)
      ? VizConfig_Source_Attribute_NumericBucketing_Intervals.fromPartial(object.intervals)
      : undefined;
    message.includeLesser = object.includeLesser ?? undefined;
    message.includeGreater = object.includeGreater ?? undefined;
    return message;
  },
};

function createBaseVizConfig_Source_Attribute_NumericBucketing_Intervals(): VizConfig_Source_Attribute_NumericBucketing_Intervals {
  return { min: 0, max: 0, count: 0 };
}

export const VizConfig_Source_Attribute_NumericBucketing_Intervals = {
  encode(
    message: VizConfig_Source_Attribute_NumericBucketing_Intervals,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.min !== 0) {
      writer.uint32(9).double(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(17).double(message.max);
    }
    if (message.count !== 0) {
      writer.uint32(24).int64(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VizConfig_Source_Attribute_NumericBucketing_Intervals {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizConfig_Source_Attribute_NumericBucketing_Intervals();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.min = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.max = reader.double();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.count = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizConfig_Source_Attribute_NumericBucketing_Intervals {
    return {
      min: isSet(object.min) ? globalThis.Number(object.min) : 0,
      max: isSet(object.max) ? globalThis.Number(object.max) : 0,
      count: isSet(object.count) ? globalThis.Number(object.count) : 0,
    };
  },

  toJSON(message: VizConfig_Source_Attribute_NumericBucketing_Intervals): unknown {
    const obj: any = {};
    if (message.min !== 0) {
      obj.min = message.min;
    }
    if (message.max !== 0) {
      obj.max = message.max;
    }
    if (message.count !== 0) {
      obj.count = Math.round(message.count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizConfig_Source_Attribute_NumericBucketing_Intervals>, I>>(
    base?: I,
  ): VizConfig_Source_Attribute_NumericBucketing_Intervals {
    return VizConfig_Source_Attribute_NumericBucketing_Intervals.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizConfig_Source_Attribute_NumericBucketing_Intervals>, I>>(
    object: I,
  ): VizConfig_Source_Attribute_NumericBucketing_Intervals {
    const message = createBaseVizConfig_Source_Attribute_NumericBucketing_Intervals();
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    message.count = object.count ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

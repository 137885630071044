// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.3
// source: criteriaselector/configschema/survey.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Column } from "../../column";
import { SortOrder } from "../../sort_order";
import { ValueConfig } from "../value_config";

export const protobufPackage = "tanagra.configschema";

export interface Survey {
  /** Columns displayed in the list view. */
  columns: Column[];
  /**
   * Entity groups where the related entity is what is selected (e.g.
   * surveyBasics when filtering surveyOccurrence).
   */
  entityGroups: Survey_EntityGroupConfig[];
  /**
   * Optional configuration of a categorical or numeric value associated with
   * the selection (e.g. a numeric answer). Applied to the entire selection
   * so generally not compatible with multi_select. Currently only one is
   * supported.
   */
  valueConfigs: ValueConfig[];
  /**
   * The sort order to use in the list view, or in hierarchies where no sort
   * order has been specified.
   */
  defaultSort:
    | SortOrder
    | undefined;
  /**
   * The attribute used to name selections if not the first column. This can be
   * used to include extra context with the selected values that's not visible
   * in the table view.
   */
  nameAttribute?: string | undefined;
}

export interface Survey_EntityGroupConfig {
  /** The id of the entity group. */
  id: string;
  /**
   * The sort order applied to this entity group when displayed in the
   * hierarchy view.
   */
  sortOrder: SortOrder | undefined;
}

function createBaseSurvey(): Survey {
  return { columns: [], entityGroups: [], valueConfigs: [], defaultSort: undefined, nameAttribute: undefined };
}

export const Survey = {
  encode(message: Survey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.entityGroups) {
      Survey_EntityGroupConfig.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.valueConfigs) {
      ValueConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.defaultSort !== undefined) {
      SortOrder.encode(message.defaultSort, writer.uint32(34).fork()).ldelim();
    }
    if (message.nameAttribute !== undefined) {
      writer.uint32(42).string(message.nameAttribute);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Survey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSurvey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entityGroups.push(Survey_EntityGroupConfig.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.valueConfigs.push(ValueConfig.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.defaultSort = SortOrder.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nameAttribute = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Survey {
    return {
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      entityGroups: globalThis.Array.isArray(object?.entityGroups)
        ? object.entityGroups.map((e: any) => Survey_EntityGroupConfig.fromJSON(e))
        : [],
      valueConfigs: globalThis.Array.isArray(object?.valueConfigs)
        ? object.valueConfigs.map((e: any) => ValueConfig.fromJSON(e))
        : [],
      defaultSort: isSet(object.defaultSort) ? SortOrder.fromJSON(object.defaultSort) : undefined,
      nameAttribute: isSet(object.nameAttribute) ? globalThis.String(object.nameAttribute) : undefined,
    };
  },

  toJSON(message: Survey): unknown {
    const obj: any = {};
    if (message.columns?.length) {
      obj.columns = message.columns.map((e) => Column.toJSON(e));
    }
    if (message.entityGroups?.length) {
      obj.entityGroups = message.entityGroups.map((e) => Survey_EntityGroupConfig.toJSON(e));
    }
    if (message.valueConfigs?.length) {
      obj.valueConfigs = message.valueConfigs.map((e) => ValueConfig.toJSON(e));
    }
    if (message.defaultSort !== undefined) {
      obj.defaultSort = SortOrder.toJSON(message.defaultSort);
    }
    if (message.nameAttribute !== undefined) {
      obj.nameAttribute = message.nameAttribute;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Survey>, I>>(base?: I): Survey {
    return Survey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Survey>, I>>(object: I): Survey {
    const message = createBaseSurvey();
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.entityGroups = object.entityGroups?.map((e) => Survey_EntityGroupConfig.fromPartial(e)) || [];
    message.valueConfigs = object.valueConfigs?.map((e) => ValueConfig.fromPartial(e)) || [];
    message.defaultSort = (object.defaultSort !== undefined && object.defaultSort !== null)
      ? SortOrder.fromPartial(object.defaultSort)
      : undefined;
    message.nameAttribute = object.nameAttribute ?? undefined;
    return message;
  },
};

function createBaseSurvey_EntityGroupConfig(): Survey_EntityGroupConfig {
  return { id: "", sortOrder: undefined };
}

export const Survey_EntityGroupConfig = {
  encode(message: Survey_EntityGroupConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.sortOrder !== undefined) {
      SortOrder.encode(message.sortOrder, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Survey_EntityGroupConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSurvey_EntityGroupConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sortOrder = SortOrder.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Survey_EntityGroupConfig {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      sortOrder: isSet(object.sortOrder) ? SortOrder.fromJSON(object.sortOrder) : undefined,
    };
  },

  toJSON(message: Survey_EntityGroupConfig): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.sortOrder !== undefined) {
      obj.sortOrder = SortOrder.toJSON(message.sortOrder);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Survey_EntityGroupConfig>, I>>(base?: I): Survey_EntityGroupConfig {
    return Survey_EntityGroupConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Survey_EntityGroupConfig>, I>>(object: I): Survey_EntityGroupConfig {
    const message = createBaseSurvey_EntityGroupConfig();
    message.id = object.id ?? "";
    message.sortOrder = (object.sortOrder !== undefined && object.sortOrder !== null)
      ? SortOrder.fromPartial(object.sortOrder)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
